export const mask = (value: string, mask: string) => {
  let i = 0;
  let maskText = "";
  for (const char of mask)
    if (char == "#" && value != undefined) {
      maskText += value[i];
      i++;
    } else maskText += char;

  return maskText;
};

export default mask;
